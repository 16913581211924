<template>
  <div class="wrapper">
    <CustomSelect v-model:selected="modelValue" :context="contextRef">
      <template #selected-option="{ value }">
        <template v-if="operatingCountries.includes(value)">
          <Flag :code="value" />&nbsp;&nbsp;{{ t(`forms.countries.${value}`) }}
        </template>
        <template v-else>
          <FaIcon icon-class="fal fa-globe" classes="ml-[3px] mr-[4px]" />
          &nbsp;&nbsp;{{ t(`forms.countries.${value}`) }}
        </template>
      </template>
      <template #option="{ value }">
        <template v-if="operatingCountries.includes(value)">
          <Flag :code="value" />&nbsp;&nbsp;{{ t(`forms.countries.${value}`) }}
        </template>
        <template v-else>
          <FaIcon icon-class="fal fa-globe" classes="ml-[3px] mr-[4px]" />
          &nbsp;&nbsp;{{ t(`forms.countries.${value}`) }}
        </template>
      </template>
    </CustomSelect>
  </div>
</template>

<script setup lang="ts">
import FaIcon from '@/components/fa-icon.vue';
import Flag from './components/flag.vue';
import CustomSelect from './customSelect.vue';
import { toRef } from 'vue';

const { t } = useTrans();

const props = defineProps({
  // eslint-disable-next-line vue/require-default-prop
  context: Object,
});

const modelValue = defineModel<object | string | null>('selected');
const operatingCountries = ['DE', 'BE', 'NL', 'LU', 'AT'];
const contextRef = toRef(props, 'context');
</script>

<style lang="postcss" scoped>
.wrapper :deep(.normal-flag) {
  @apply !origin-top;
}
</style>
